import * as React from "react";
import { LayoutGridElement, LayoutGridItemElement } from "@expediagroup/mojo-protocol-typescript-client";
import { EGDSLayoutGrid, EGDSLayoutGridItem, EGDSLayoutGridItemProps } from "@egds/react-core/layout-grid";
import { mjRenderChildren } from "../MJRenderChildren";
import { Viewport, ViewSmall, ViewMedium, ViewLarge } from "@shared-ui/viewport-context";

export const MJLayoutGrid: React.FC<{ element: LayoutGridElement }> = ({ element }) => {
  const columns = element.columns ? extractValuesFromMap(element.columns, "count") : undefined;

  return (
    <EGDSLayoutGrid style={{ gap: `${element["spacing"]}` }} columns={columns}>
      {mjRenderChildren(element.children)}
    </EGDSLayoutGrid>
  );
};

export const MJLayoutGridItem: React.FC<{ element: LayoutGridItemElement }> = ({ element }) => {
  const colSpan = element.colSpan ? extractValuesFromMap(element.colSpan, "count") : undefined;

  const alignSelf = element.align as EGDSLayoutGridItemProps["alignSelf"];
  const justifySelf = element.justify as EGDSLayoutGridItemProps["justifySelf"];

  if (!colSpan) {
    return (
      <EGDSLayoutGridItem colSpan={colSpan} alignSelf={alignSelf} justifySelf={justifySelf}>
        <div>{mjRenderChildren(element.children)}</div>
      </EGDSLayoutGridItem>
    );
  }

  return (
    <Viewport>
      <ViewSmall>
        {colSpan["small"] === 0 ? (
          <></>
        ) : (
          <EGDSLayoutGridItem colSpan={colSpan} alignSelf={alignSelf} justifySelf={justifySelf}>
            <div>{mjRenderChildren(element.children)}</div>
          </EGDSLayoutGridItem>
        )}
      </ViewSmall>
      <ViewMedium>
        {colSpan["medium"] === 0 ? (
          <></>
        ) : (
          <EGDSLayoutGridItem colSpan={colSpan} alignSelf={alignSelf} justifySelf={justifySelf}>
            <div>{mjRenderChildren(element.children)}</div>
          </EGDSLayoutGridItem>
        )}
      </ViewMedium>
      <ViewLarge>
        {colSpan["large"] === 0 ? (
          <></>
        ) : (
          <EGDSLayoutGridItem colSpan={colSpan} alignSelf={alignSelf} justifySelf={justifySelf}>
            <div>{mjRenderChildren(element.children)}</div>
          </EGDSLayoutGridItem>
        )}
      </ViewLarge>
    </Viewport>
  );
};

const extractValuesFromMap = <T, K extends keyof T>(map: Record<string, T>, key: K): Record<string, T[K]> => {
  return Object.keys(map).reduce((acc: Record<string, T[K]>, mapKey) => {
    acc[mapKey] = map[mapKey][key];
    return acc;
  }, {});
};
